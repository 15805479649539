import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledIconButtonProps } from './IconButton.style.types'

import localThemes from '../themes/IconButton.theme'

export const IconButton = styled(Interaction)((props: StyledIconButtonProps): FlattenSimpleInterpolation => {
const localTheme = localThemes(props.theme)

return css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  height: ${localTheme.size[props.size!].sm.buttonSize}px;
  width: ${localTheme.size[props.size!].sm.buttonSize}px;
  border-radius: ${props.theme.border.radius[2]};
  font-weight: ${props.theme.fontWeight[2]};
  background: ${localTheme.appearance[props.appearance!].backgroundColor};
  padding: ${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[3]}px;
  border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].border};

  ${props.theme.mixins.respondTo.md(css`
    height: ${localTheme.size[props.size!].md.buttonSize}px;
    width: ${localTheme.size[props.size!].md.buttonSize}px;
  `)}

  [class*='Icon'] {
    * {
      ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].iconColor)};
    }
  }

  &:focus {
    background: ${localTheme.appearance[props.appearance!].selected.backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].selected.border};

    [class*='Icon'] {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].selected.iconColor)};
      }
    }
  }

  &:hover {
    background: ${localTheme.appearance[props.appearance!].hover.backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].hover.border};

    [class*='Icon'] {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].hover.iconColor)};
      }
    }
  }

  ${props.state === 'disabled' && css`
    cursor: default;
  `}

  ${props.shape === 'circle' && css`
    border-radius: 50%;
  `}

  ${props.state && css`
    background: ${localTheme.state[props.state!].backgroundColor};
    border: none;

    [class*='Icon'] {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].iconColor)};
      }
    }

    &:hover {
      background: ${localTheme.state[props.state!].hover.backgroundColor};

      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].hover.iconColor)};
        }
      }
    }

    &:focus {
      background: ${localTheme.state[props.state!].selected.backgroundColor};

      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].selected.iconColor)};
        }
      }
    }
  `}

  ${props.inverse && css`
    background: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.border};

    [class*='Icon'] {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.iconColor)};
      }
    }

    &:hover {
      background: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.hover.border};

      [class*='Icon'] {
        * {
         ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.hover.iconColor)}
        }
      }
    }

    &:focus {
      background: ${localTheme.appearance[props.appearance!].inverse.selected.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.selected.border};

      [class*='Icon'] {
        * {
         ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.selected.iconColor)};
        }
      }
    }
  `}

  ${props.state && props.inverse && css`
    background: ${localTheme.state[props.state!].inverse.backgroundColor};

    [class*='Icon'] {
      * {
         ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].inverse.iconColor)};
      }
    }

    &:hover {
      background: ${localTheme.state[props.state!].inverse.hover.backgroundColor};

      [class*='Icon'] {
        * {
         ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].inverse.hover.iconColor)};
        }
      }
    }

    &:focus {
      background: ${localTheme.state[props.state!].inverse.selected.backgroundColor};

      [class*='Icon'] {
        * {
         ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].inverse.selected.iconColor)};
        }
      }
    }
  `}
`})
