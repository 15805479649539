import { Breakpoints } from './breakpoints.types'

const breakpoints: Breakpoints = {
  sm: 0,
  md: 601,
  lg: 881,
  xl: 1264,
}

export default breakpoints
