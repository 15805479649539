import React, { PureComponent, ReactNode } from 'react'

import Button from '@vfuk/core-button'
import Heading from '@vfuk/core-heading'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'

import * as Styled from './styles/CardBody.style'

import { CardBodyProps } from './CardBody.types'

export default class CardBody extends PureComponent<CardBodyProps> {
  public static defaultProps: Partial<CardBodyProps> = {
    centerButtons: false,
  }

  public render(): ReactNode {
    return (
      <Styled.CardBody>
        <Styled.ContentWrapper>
          <Styled.ContentWrapperHeader hasChevron={!!this.props.chevron}>
            <If condition={this.props.chevron}>
              <Icon {...this.props.chevron} name='chevron-right' size={3} group='system' />
            </If>
            <Heading {...this.props.heading} weight={1} />
          </Styled.ContentWrapperHeader>
          <If condition={this.props.text}>
            <Paragraph>{this.props.text}</Paragraph>
          </If>
        </Styled.ContentWrapper>
        <If condition={this.props.primaryButton && this.props.primaryButton.text}>
          <Styled.ButtonWrapper centerButtons={this.props.centerButtons!}>
            <Button {...this.props.primaryButton!} />
            <If condition={this.props.secondaryButton && this.props.secondaryButton.text}>
              <Button {...this.props.secondaryButton!} />
            </If>
          </Styled.ButtonWrapper>
        </If>
      </Styled.CardBody>
    )
  }
}
