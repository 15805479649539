import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import localThemes from './themes/IconButton.theme'

import * as Styled from './styles/IconButton.style'

import { IconButtonProps } from './IconButton.types'

export class IconButton extends PureComponent<IconButtonProps> {
  public static defaultProps: Partial<IconButtonProps> = {
    shape: 'square',
    size: 5,
    appearance: 'primary',
    htmlAttributes: {},
  }

  public render(): ReactNode {
    const localTheme = localThemes(this.props.theme!)
    return (
      <Styled.IconButton
        appearance={this.props.appearance}
        target={this.props.htmlAttributes!.target}
        type={this.props.htmlAttributes!.type}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        size={this.props.size}
        shape={this.props.shape}
        inverse={this.props.inverse}
        state={this.props.state}
        onInteraction={this.props.onClick}
        disabled={this.props.state === 'disabled'}
        label={this.props.srText}
        to={this.props.to}
        href={this.props.href}
      >
        <Icon name={this.props.icon.name} size={localTheme.size[this.props.size!].iconSize} />
      </Styled.IconButton>
    )
  }
}

export default withTheme(IconButton)
