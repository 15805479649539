import React, { PureComponent, ReactNode } from 'react'

import * as Styled from './styles/Paragraph.style'

import { ParagraphProps } from './Paragraph.types'

export default class Paragraph extends PureComponent<ParagraphProps> {
  private static defaultProps: Partial<ParagraphProps> = {
    size: 3,
    noMargin: false,
  }

  public render(): ReactNode {
    return (
      <Styled.Paragraph size={this.props.size!} justify={this.props.justify} weight={this.props.weight} noMargin={this.props.noMargin!}>
        {this.props.children}
      </Styled.Paragraph>
    )
  }
}
