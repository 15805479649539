import { Typography } from './typography.types'
import spacing from './spacing'
import fontWeight from './fontWeight'

const typography: Typography = {
  paragraph: {
    1: {
      fontWeight: fontWeight[2],

      fontMobile: '12px',
      lineHeightMobile: '16px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '12px',
      lineHeightTablet: '16px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[3]}px`,

      fontDesktop: '12px',
      lineHeightDesktop: '16px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[3]}px`,
    },

    2: {
      fontWeight: fontWeight[2],

      fontMobile: '14px',
      lineHeightMobile: '18px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '14px',
      lineHeightTablet: '18px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[3]}px`,

      fontDesktop: '14px',
      lineHeightDesktop: '18px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[3]}px`,
    },

    3: {
      fontWeight: fontWeight[2],

      fontMobile: '16px',
      lineHeightMobile: '20px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '18px',
      lineHeightTablet: '26px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[3]}px`,

      fontDesktop: '18px',
      lineHeightDesktop: '26px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[3]}px`,
    },
  },

  heading: {
    1: {
      fontWeight: fontWeight[2],

      fontMobile: '18px',
      lineHeightMobile: '26px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[2]}px`,

      fontTablet: '20px',
      lineHeightTablet: '28px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[2]}px`,

      fontDesktop: '20px',
      lineHeightDesktop: '28px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[2]}px`,
    },

    2: {
      fontWeight: fontWeight[1],

      fontMobile: '20px',
      lineHeightMobile: '28px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '24px',
      lineHeightTablet: '32px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[3]}px`,

      fontDesktop: '28px',
      lineHeightDesktop: '32px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[3]}px`,
    },

    3: {
      fontWeight: fontWeight[1],

      fontMobile: '24px',
      lineHeightMobile: '32px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '32px',
      lineHeightTablet: '40px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[5]}px`,

      fontDesktop: '40px',
      lineHeightDesktop: '48px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[5]}px`,
    },

    4: {
      fontWeight: fontWeight[1],

      fontMobile: '28px',
      lineHeightMobile: '36px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '40px',
      lineHeightTablet: '48px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[3]}px`,

      fontDesktop: '56px',
      lineHeightDesktop: '64px',
      letterSpacingDesktop: '0',
      marginBottomDesktop: `${spacing.fixed[5]}px`,
    },

    5: {
      fontWeight: fontWeight[1],

      fontMobile: '32px',
      lineHeightMobile: '40px',
      letterSpacingMobile: '0',
      marginBottomMobile: `${spacing.fixed[3]}px`,

      fontTablet: '56px',
      lineHeightTablet: '64px',
      letterSpacingTablet: '0',
      marginBottomTablet: `${spacing.fixed[5]}px`,

      fontDesktop: '64px',
      lineHeightDesktop: '72px',
      letterSpacingDesktop: '-0.5px',
      marginBottomDesktop: `${spacing.fixed[5]}px`,
    },
  },
}

export default typography
