/* eslint-disable max-len */
import { BorderConfig } from '@vfuk/core-styles/dist/mixins/themedBorderGenerator.types'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ButtonTheme, StateProps } from './Button.theme.types'

const getCommonBorderStyle = (theme: Theme): BorderConfig => {
  return {
    width: theme.border.width[2],
    style: theme.border.style[1],
    colors: {
      outerBorderColor: 'transparent',
    },
    prefix: '&:after',
  }
}

const getStateSuccess = (theme: Theme, commonBorderStyle: BorderConfig): StateProps => {
  return {
    opacity: 1,
    color: theme.color.primary2,
    backgroundColor: theme.color.accent2,
    iconAppearance: 'primary',
    inverseIcon: true,
    border: { ...commonBorderStyle },
    hover: {
      color: theme.color.primary2,
      backgroundColor: '#53a10b',
      iconAppearance: 'primary',
    },
  }
}

const getStateSelected = (theme: Theme, commonBorderStyle: BorderConfig): StateProps => {
  return getStateSuccess(theme, commonBorderStyle)
}

const getStateDisabled = (theme: Theme, commonBorderStyle: BorderConfig): StateProps => {
  return {
    opacity: 1,
    color: theme.color.monochrome6,
    backgroundColor: theme.color.monochrome4,
    iconAppearance: 'primary',
    inverseIcon: true,
    border: { ...commonBorderStyle },
    hover: {
      color: theme.color.monochrome6,
      backgroundColor: theme.color.monochrome4,
      iconAppearance: 'primary',
    },
  }
}

const buttonLocal = (theme: Theme): ButtonTheme => {
  const commonBorderStyle = getCommonBorderStyle(theme)
  const stateSelected = getStateSelected(theme, commonBorderStyle)
  const stateDisabled = getStateDisabled(theme, commonBorderStyle)
  const stateSuccess = getStateSuccess(theme, commonBorderStyle)
  return {
    appearance: {
      primary: {
        color: theme.color.primary2,
        backgroundColor: theme.color.primary1,
        iconAppearance: 'primary',
        inverseIcon: true,
        border: { ...commonBorderStyle },
        hover: {
          color: theme.color.primary2,
          backgroundColor: theme.color.accent3,
          iconAppearance: 'secondary',
        },
        state: {
          success: stateSuccess,
          selected: stateSelected,
          disabled: stateDisabled,
        },
        inverse: {
          color: theme.color.primary2,
          backgroundColor: theme.color.primary1,
          iconAppearance: 'primary',
          inverseIcon: true,
          border: { ...commonBorderStyle },
          hover: {
            color: theme.color.primary2,
            backgroundColor: theme.color.accent3,
            iconAppearance: 'secondary',
          },
          state: {
            success: stateSuccess,
            selected: stateSelected,
            disabled: stateDisabled,
          },
        },
      },
      secondary: {
        color: theme.color.primary2,
        backgroundColor: theme.color.primary3,
        iconAppearance: 'primary',
        inverseIcon: true,
        border: { ...commonBorderStyle },
        hover: {
          color: theme.color.primary2,
          backgroundColor: theme.color.monochrome7,
          iconAppearance: 'secondary',
        },
        state: {
          success: stateSuccess,
          selected: stateSelected,
          disabled: stateDisabled,
        },
        inverse: {
          color: theme.color.primary3,
          backgroundColor: theme.color.monochrome1,
          iconAppearance: 'primary',
          inverseIcon: false,
          border: { ...commonBorderStyle },
          hover: {
            color: theme.color.primary3,
            backgroundColor: theme.color.monochrome3,
            iconAppearance: 'primary',
          },
          state: {
            success: stateSuccess,
            selected: stateSelected,
            disabled: stateDisabled,
          },
        },
      },
      alt1: {
        color: theme.color.primary3,
        backgroundColor: 'transparent',
        iconAppearance: 'primary',
        inverseIcon: false,
        border: {
          width: theme.border.width[2],
          style: theme.border.style[1],
          colors: {
            outerBorderColor: theme.color.primary3,
          },
          prefix: '&:after',
        },
        hover: {
          color: theme.color.primary2,
          backgroundColor: theme.color.primary3,
          iconAppearance: 'primary',
        },
        state: {
          success: {
            ...stateSuccess,
          },
          selected: stateSelected,
          disabled: stateDisabled,
        },
        inverse: {
          color: theme.color.primary2,
          backgroundColor: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: true,
          border: {
            width: theme.border.width[2],
            style: theme.border.style[1],
            colors: {
              outerBorderColor: theme.color.primary2,
            },
            prefix: '&:after',
          },
          hover: {
            color: theme.color.primary3,
            backgroundColor: theme.color.monochrome1,
            iconAppearance: 'alt1',
          },
          state: {
            success: stateSuccess,
            selected: stateSelected,
            disabled: stateDisabled,
          },
        },
      },
      alt2: {
        color: theme.color.primary1,
        backgroundColor: 'transparent',
        iconAppearance: 'secondary',
        inverseIcon: false,
        border: {
          width: theme.border.width[2],
          style: theme.border.style[1],
          colors: {
            outerBorderColor: theme.color.primary1,
          },
          prefix: '&:after',
        },
        hover: {
          color: theme.color.primary2,
          backgroundColor: theme.color.primary1,
          iconAppearance: 'alt1',
        },
        state: {
          success: stateSuccess,
          selected: stateSelected,
          disabled: stateDisabled,
        },
        inverse: {
          color: theme.color.primary2,
          backgroundColor: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: true,
          border: {
            width: theme.border.width[2],
            style: theme.border.style[1],
            colors: {
              outerBorderColor: theme.color.primary2,
            },
            prefix: '&:after',
          },
          hover: {
            color: theme.color.primary3,
            backgroundColor: theme.color.monochrome1,
            iconAppearance: 'alt1',
          },
          state: {
            success: stateSuccess,
            selected: stateSelected,
            disabled: stateDisabled,
          },
        },
      },
      alt3: {
        color: theme.color.primary2,
        backgroundColor: theme.color.secondary1,
        iconAppearance: 'primary',
        inverseIcon: true,
        border: { ...commonBorderStyle },
        hover: {
          color: theme.color.primary2,
          backgroundColor: theme.color.secondary4,
          iconAppearance: 'secondary',
        },
        state: {
          success: stateSuccess,
          selected: stateSelected,
          disabled: stateDisabled,
        },
        inverse: {
          color: theme.color.primary2,
          backgroundColor: theme.color.secondary1,
          iconAppearance: 'primary',
          inverseIcon: true,
          border: { ...commonBorderStyle },
          hover: {
            color: theme.color.primary2,
            backgroundColor: theme.color.secondary4,
            iconAppearance: 'secondary',
          },
          state: {
            success: stateSuccess,
            selected: stateSelected,
            disabled: stateDisabled,
          },
        },
      },
    },
    minWidth: '192px',
    inverse: false,
    borderRadius: theme.border.radius[2],
    focus: theme.color.focus,
  }
}

export default buttonLocal
