import React, { PureComponent, ReactNode } from 'react'

import * as Styled from './styles/Heading.style'

import { HeadingProps } from './Heading.types'

export default class Heading extends PureComponent<HeadingProps> {
  public static defaultProps: Partial<HeadingProps> = {
    level: 2,
    noMargin: false,
    appearance: 'primary',
    inverse: false,
  }

  public render(): ReactNode {
    return (
      <Styled.Heading
        as={`h${this.props.level}` as React.ElementType}
        level={this.props.level!}
        size={this.props.size}
        justify={this.props.justify}
        appearance={this.props.appearance!}
        inverse={this.props.inverse!}
        weight={this.props.weight}
        noMargin={this.props.noMargin!}
      >
        <Choose>
          <When condition={this.props.text}>{this.props.text}</When>
          <Otherwise>{this.props.children}</Otherwise>
        </Choose>
      </Styled.Heading>
    )
  }
}
