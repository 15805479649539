import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledStateNotificationProps } from './StateNotification.style.types'

type StateNotificationProps = Pick<StyledStateNotificationProps, 'theme'>
export const StateNotification = styled.div((props: StateNotificationProps): FlattenSimpleInterpolation => css`
  margin: -${props.theme.spacing.fixed[4]}px;
  display: flex;
`)

export const IconWrapper = styled.div((props: StyledStateNotificationProps): FlattenSimpleInterpolation => css`
  padding: ${props.theme.spacing.fixed[3]}px;

  ${props.level === 1 && css`
    background: ${props.theme.color[props.state]};
  `}

  ${props.level === 2 && css`
    padding-right: 0;
  `}
`)

type ContentWrapperProps = Pick<StyledStateNotificationProps, 'theme'>
export const ContentWrapper = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  padding: ${props.theme.spacing.fixed[3]}px;
  display: flex;
  align-items: center;
`)
