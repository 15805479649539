import { Spacing } from './spacing.types'

const fixed = {
  0: 0,
  1: 4,
  2: 8,
  3: 16,
  4: 20,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 56,
  10: 64,
  11: 72,
  12: 80,
}

const spacing: Spacing = {
  fixed,
  responsive: {
    0: {
      sm: fixed[0],
      md: fixed[0],
      lg: fixed[0],
      xl: fixed[0],
    },
    1: {
      sm: fixed[1],
      md: fixed[1],
      lg: fixed[1],
      xl: fixed[1],
    },
    2: {
      sm: fixed[2],
      md: fixed[2],
      lg: fixed[2],
      xl: fixed[2],
    },
    3: {
      sm: 12,
      md: 12,
      lg: fixed[3],
      xl: fixed[3],
    },
    4: {
      sm: fixed[3],
      md: fixed[3],
      lg: fixed[4],
      xl: fixed[4],
    },
    5: {
      sm: fixed[4],
      md: fixed[4],
      lg: fixed[5],
      xl: fixed[5],
    },
    6: {
      sm: fixed[5],
      md: 28,
      lg: fixed[6],
      xl: fixed[6],
    },
    7: {
      sm: fixed[5],
      md: fixed[6],
      lg: fixed[7],
      xl: fixed[7],
    },
    8: {
      sm: fixed[6],
      md: fixed[7],
      lg: fixed[8],
      xl: fixed[8],
    },
    9: {
      sm: fixed[7],
      md: fixed[8],
      lg: fixed[9],
      xl: fixed[9],
    },
    10: {
      sm: fixed[8],
      md: fixed[9],
      lg: fixed[10],
      xl: fixed[10],
    },
    11: {
      sm: fixed[8],
      md: fixed[9],
      lg: fixed[11],
      xl: fixed[11],
    },
    12: {
      sm: fixed[8],
      md: fixed[9],
      lg: fixed[12],
      xl: fixed[12],
    },
  },
}

export default spacing
