import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import baseFont from '@vfuk/core-styles/dist/constants/baseFont'

import { ContainerTheme } from './Container.theme.types'

const containerLocal = (theme: Theme): ContainerTheme => {
  return {
    paddingLevel: {
      0: {
        spacingValue: 0,
      },
      1: {
        spacingValue: 2,
      },
      2: {
        spacingValue: 3,
      },
      3: {
        spacingValue: 4,
      },
      4: {
        spacingValue: 5,
      },
      5: {
        spacingValue: 6,
      },
      6: {
        spacingValue: 7,
      },
    },
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1,
        color: baseFont.color,
        highlight: {
          primary: {
            borderColor: theme.color.primary1,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: theme.color.secondary1,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: theme.color.secondary7,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: theme.color.warn,
          },
          error: {
            borderColor: theme.color.error,
          },
          success: {
            borderColor: theme.color.success,
          },
          info: {
            borderColor: theme.color.info,
          },
          incomplete: {
            borderColor: theme.color.incomplete,
          },
        },
      },
      secondary: {
        backgroundColor: theme.color.monochrome3,
        color: theme.color.monochrome8,
        highlight: {
          primary: {
            borderColor: theme.color.primary1,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: theme.color.secondary1,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: theme.color.secondary7,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: theme.color.warn,
          },
          error: {
            borderColor: theme.color.error,
          },
          success: {
            borderColor: theme.color.success,
          },
          info: {
            borderColor: theme.color.info,
          },
          incomplete: {
            borderColor: theme.color.incomplete,
          },
        },
      },
      alt1: {
        backgroundColor: theme.color.monochrome8,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt2: {
        backgroundColor: theme.color.monochrome10,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt3: {
        backgroundColor: theme.color.monochrome10,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt4: {
        backgroundColor: theme.color.primary1,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt5: {
        backgroundColor: theme.color.monochrome5,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt6: {
        backgroundColor: theme.color.secondary1,
        color: theme.color.primary2,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
    },
  }
}

export default containerLocal
