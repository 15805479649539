/* eslint-disable global-require, sonarjs/cognitive-complexity */
import React, { ReactElement } from 'react'
import { ReactSVG } from 'react-svg'

import { ThemeName } from '@vfuk/core-styles/dist/constants/themeName.types'

import { AssetType } from '@vfuk/core-styles/dist/constants/assetLocation.types'

import spacing from '@vfuk/core-styles/dist/constants/spacing'
import breakpoints from '@vfuk/core-styles/dist/constants/breakpoints'
import globalStyles from '@vfuk/core-styles/dist/globals'
import typography from '@vfuk/core-styles/dist/constants/typography'
import fonts from '@vfuk/core-styles/dist/constants/fonts'
import baseFont from '@vfuk/core-styles/dist/constants/baseFont'
import fontWeight from '@vfuk/core-styles/dist/constants/fontWeight'
import border from '@vfuk/core-styles/dist/constants/border'

import mixinTypography from '@vfuk/core-styles/dist/mixins/typography'
import mixinRespondTo from '@vfuk/core-styles/dist/mixins/respondTo'
import mixinSpacing from '@vfuk/core-styles/dist/mixins/spacing'
import mixinAdvancedSpacing from '@vfuk/core-styles/dist/mixins/advancedSpacing'
import mixinBackgroundImage from '@vfuk/core-styles/dist/mixins/backgroundImage'
import mixinIconAppearance from '@vfuk/core-styles/dist/mixins/iconAppearance'
import mixinElevation from '@vfuk/core-styles/dist/mixins/elevation'
import mixinResponsiveSizing from '@vfuk/core-styles/dist/mixins/responsiveSizing'
import mixinSrOnly from '@vfuk/core-styles/dist/mixins/srOnly'
import mixinThemedBorderGenerator from '@vfuk/core-styles/dist/mixins/themedBorderGenerator'

import animationFadeIn from '@vfuk/core-styles/dist/animations/fadeIn'
import animationFadeOut from '@vfuk/core-styles/dist/animations/fadeOut'
import animationScaleUp from '@vfuk/core-styles/dist/animations/scaleUp'
import animationScaleDown from '@vfuk/core-styles/dist/animations/scaleDown'
import animationSlideFromBottom from '@vfuk/core-styles/dist/animations/slideFromBottom'
import animationSlideFromLeft from '@vfuk/core-styles/dist/animations/slideFromLeft'
import animationSlideFromRight from '@vfuk/core-styles/dist/animations/slideFromRight'
import animationSlideFromTop from '@vfuk/core-styles/dist/animations/slideFromTop'
import animationSlideToBottom from '@vfuk/core-styles/dist/animations/slideToBottom'
import animationSlideToLeft from '@vfuk/core-styles/dist/animations/slideToLeft'
import animationSlideToRight from '@vfuk/core-styles/dist/animations/slideToRight'
import animationSlideToTop from '@vfuk/core-styles/dist/animations/slideToTop'
import animationNone from '@vfuk/core-styles/dist/animations/none'

import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import { IconRuleSet, AllSizes, RequiredSizes } from '@vfuk/core-icon-generator/dist/IconGenerator.types'

import colors from './constants/colors'
import elevation from './constants/elevation'
import gridConfig from './constants/gridConfig'
import IconsRules from './constants/icons'

export class WS10Theme implements Theme {
  /**
   * Theme name
   */
  public name = 'WS10' as ThemeName

  /**
   * Font name
   * Font files has to be located in /assets/fonts/*
   */
  public fonts = fonts

  /**
   * Theme base font
   * Set of font css properties used by global stylesheet
   */
  public baseFont = baseFont

  /**
   * Font weight
   */
  public fontWeight = fontWeight

  /**
   * Theme function colors
   * Only functional colors are allowed to be exported by theme
   * Direct color use is forbidden due to theme functionality
   *
   * @default @vfuk/core-styles/dist/constants/functionalColors
   */
  public color = colors

  /**
   * Global styles
   * injected on the page by styled-components
   *
   * @default @vfuk/core-styles/dist/constants/globalStyles
   */
  public globalStyles = globalStyles

  /**
   * Theme spacing
   *
   * @default @vfuk/core-styles/dist/constants/spacing
   */
  public spacing = spacing

  /**
   * Theme heading size
   *
   * @default @vfuk/core-styles/dist/constants/headingSize
   */
  public typography = typography

  /**
   * Theme breakpoints
   *
   * @default @vfuk/core-styles/dist/constants/breakpoints
   */
  public breakpoints = breakpoints

  /**
   * Grid config consumed by Grid Provider
   *
   * @default @vfuk/core-styles/dist/constants/gridConfig
   */
  public gridConfig = gridConfig

  /**
   * Icon rules
   */
  public iconRules = IconsRules

  /**
   * elevation
   *
   * @default @vfuk/core-styles/dist/constants/elevation
   */
  public elevation = elevation

  /**
   * Border related values - radius and width
   *
   * @default @vfuk/core-styles/dist/constants/border
   */
  public border = border

  /**
   * Theme mixins
   *
   * @default @vfuk/core-styles/dist/mixins/index.ts
   */
  public mixins = {
    typography: mixinTypography,
    respondTo: mixinRespondTo,
    spacing: mixinSpacing,
    advancedSpacing: mixinAdvancedSpacing,
    bgImage: mixinBackgroundImage,
    elevation: mixinElevation,
    iconAppearance: mixinIconAppearance,
    responsiveSizing: mixinResponsiveSizing,
    srOnly: mixinSrOnly,
    themedBorderGenerator: mixinThemedBorderGenerator,
  }

  /**
   * Animation presets
   */
  public animations = {
    fadeIn: animationFadeIn,
    fadeOut: animationFadeOut,
    scaleUp: animationScaleUp,
    scaleDown: animationScaleDown,
    slideFromBottom: animationSlideFromBottom,
    slideFromLeft: animationSlideFromLeft,
    slideFromRight: animationSlideFromRight,
    slideFromTop: animationSlideFromTop,
    slideToBottom: animationSlideToBottom,
    slideToLeft: animationSlideToLeft,
    slideToRight: animationSlideToRight,
    slideToTop: animationSlideToTop,
    none: animationNone,
  }

  /**
   * Base Asset Location
   */
  private baseAssetLocation = `assets/${this.name.toLowerCase()}/`

  /**
   * Set Base Asset Location
   */
  public setBaseAssetLocation = (path: string): void => {
    this.baseAssetLocation = path
  }

  /**
   * Asset Locations
   */
  private assetLocations = {
    fonts: 'fonts/',
    icons: 'icons/',
    logos: 'logos/',
    animations: 'animations/',
  }

  /**
   * Get Asset Locations
   */
  public getAssetLocation = (assetType: AssetType): string => {
    return `${this.baseAssetLocation}${this.assetLocations[assetType]}`
  }

  /**
   * Set Asset Locations
   */
  public setAssetLocations = (assetType: AssetType, path: string): void => {
    this.assetLocations[assetType] = path.endsWith('/') ? path : `${path}/`
  }

  /**
   * Set Icon Group
   */
  public setIconGroup = (
    groupName: string,
    assetLocation: string,
    ruleSet?: (theme: Theme) => IconRuleSet<AllSizes | RequiredSizes>,
  ): void => {
    const iconSet = this.iconRules[groupName]

    // Updating existing Icon set
    if (iconSet) {
      iconSet.assetLocation = assetLocation
      iconSet.properties = ruleSet || iconSet.properties
    }

    // Adding a new Icon set
    if (!iconSet) {
      for (const group in this.iconRules) {
        if (this.iconRules[group].assetLocation === assetLocation) console.error('The asset location you are trying to use already exists')
      }

      this.iconRules[groupName] = {
        assetLocation,
        properties: ruleSet || this.iconRules.default.properties,
      }
    }
  }

  /**
   * Get svg icon
   * On demand svg loading
   * @param iconName
   * @param groupName
   * @param ErrorComponent
   */
  public getIcon = (iconName: string, groupName: string, ErrorComponent?: ReactElement): ReactElement => {
    return (
      <ReactSVG
        fallback={(): ReactElement => <>{ErrorComponent || null}</>}
        src={`${this.baseAssetLocation}${this.assetLocations.icons}${this.iconRules[groupName]?.assetLocation}/${iconName}.svg`}
        wrapper='span'
      />
    )
  }

  /**
   * Checks if the icon group exists
   * @param groupName
   * @returns boolean
   */
  public doesIconGroupExist = (groupName: string): boolean => {
    return !!this.iconRules[groupName]
  }

  /**
   * Get icon properties
   * @param groupName
   */
  public getIconProperties = (groupName: string | 'default'): IconRuleSet<AllSizes | RequiredSizes> => {
    return this.iconRules[groupName].properties(this)
  }

  /**
   * Get svg logo
   * On demand svg loading
   * @param logoName
   */
  public getLogo = (logoName: string): ReactElement => {
    return <ReactSVG src={`${this.getAssetLocation('logos')}${logoName}.svg`} wrapper='span' />
  }
}

export default new WS10Theme()
/* eslint-enable global-require */
