import { css, FlattenSimpleInterpolation } from 'styled-components'

import respondTo from './respondTo'

import { ParagraphTypographyMixin, HeadingTypographyMixin } from './typography.types'

import { ParagraphTypeKeys, HeadingTypeKeys } from '../constants/typography.types'

import { Theme } from '../themes/themes.types'
import createMixin from '../utils/createMixin'

const paragraphMixin = createMixin(
  (theme: Theme, size: ParagraphTypeKeys, withMargin: boolean = true): FlattenSimpleInterpolation =>
    theme.typography.paragraph[size] &&
    css`
      margin-top: 0;
      font-weight: ${theme.typography.paragraph[size].fontWeight};
      font-size: ${theme.typography.paragraph[size].fontMobile};
      line-height: ${theme.typography.paragraph[size].lineHeightMobile};
      letter-spacing: ${theme.typography.paragraph[size].letterSpacingMobile};
      margin-bottom: ${withMargin ? theme.typography.paragraph[size].marginBottomMobile : 0};

      ${respondTo.md(css`
        font-size: ${theme.typography.paragraph[size].fontTablet};
        line-height: ${theme.typography.paragraph[size].lineHeightTablet};
        letter-spacing: ${theme.typography.paragraph[size].letterSpacingTablet};
        margin-bottom: ${withMargin ? theme.typography.paragraph[size].marginBottomTablet : 0};
      `)}

      ${respondTo.lg(css`
        font-size: ${theme.typography.paragraph[size].fontDesktop};
        line-height: ${theme.typography.paragraph[size].lineHeightDesktop};
        letter-spacing: ${theme.typography.paragraph[size].letterSpacingDesktop};
        margin-bottom: ${withMargin ? theme.typography.paragraph[size].marginBottomDesktop : 0};
      `)}
    `,
) as ParagraphTypographyMixin

const headingMixin = createMixin(
  (theme: Theme, size: HeadingTypeKeys, withMargin: boolean = true): FlattenSimpleInterpolation =>
    theme.typography.heading[size] &&
    css`
      margin-top: 0;
      font-weight: ${theme.typography.heading[size].fontWeight};
      font-size: ${theme.typography.heading[size].fontMobile};
      line-height: ${theme.typography.heading[size].lineHeightMobile};
      letter-spacing: ${theme.typography.heading[size].letterSpacingMobile};
      margin-bottom: ${withMargin ? theme.typography.heading[size].marginBottomMobile : 0};

      ${respondTo.md(css`
        font-size: ${theme.typography.heading[size].fontTablet};
        line-height: ${theme.typography.heading[size].lineHeightTablet};
        letter-spacing: ${theme.typography.heading[size].letterSpacingTablet};
        margin-bottom: ${withMargin ? theme.typography.heading[size].marginBottomTablet : 0};
      `)}

      ${respondTo.lg(css`
        font-size: ${theme.typography.heading[size].fontDesktop};
        line-height: ${theme.typography.heading[size].lineHeightDesktop};
        letter-spacing: ${theme.typography.heading[size].letterSpacingDesktop};
        margin-bottom: ${withMargin ? theme.typography.heading[size].marginBottomDesktop : 0};
      `)}
    `,
) as HeadingTypographyMixin

export default {
  heading: headingMixin,
  paragraph: paragraphMixin,
}
