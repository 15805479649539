import React, { PureComponent, ReactNode } from 'react'

import * as Styled from './styles/Link.style'

import { LinkProps } from './Link.types'

export default class Link extends PureComponent<LinkProps> {
  public static defaultProps: Partial<LinkProps> = {
    htmlAttributes: {},
    showUnderline: false,
    appearance: 'primary',
  }

  public render(): ReactNode {
    return (
      <Styled.Link
        href={this.props.href}
        to={this.props.to}
        target={this.props.htmlAttributes!.target}
        appearance={this.props.appearance!}
        onInteraction={this.props.onClick}
        showUnderline={this.props.showUnderline!}
        inverse={this.props.inverse}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
      >
        {this.props.text || this.props.children}
      </Styled.Link>
    )
  }
}
