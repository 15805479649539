import React, { PureComponent, ReactNode } from 'react'

import Container from '@vfuk/core-container'
import Heading from '@vfuk/core-heading'
import Icon from '@vfuk/core-icon'

import { IconProps } from '@vfuk/core-icon/dist/Icon.types'

import { StateNotificationProps } from './StateNotification.types'

import * as Styled from './styles/StateNotification.style'

export default class StateNotification extends PureComponent<StateNotificationProps> {
  public static defaultProps: Partial<StateNotificationProps> = {
    level: 1,
  }

  private getIconName(level: StateNotificationProps['level'], state: StateNotificationProps['state']): IconProps['name'] {
    if (level === 1) {
      return `${state}-inverse` as IconProps['name']
    }
    return `${state}` as IconProps['name']
  }

  public render(): ReactNode {
    return (
      <Container state={this.props.state} allowOverflow={false}>
        <Styled.StateNotification>
          <Styled.IconWrapper level={this.props.level!} state={this.props.state}>
            <Icon name={this.getIconName(this.props.level, this.props.state)} group='state' size={3} isResponsive />
          </Styled.IconWrapper>
          <Styled.ContentWrapper>
            <div>
              <If condition={this.props.headingText}>
                <Heading text={this.props.headingText} level={2} size={1} weight={3} />
              </If>
              <If condition={this.props.children}>{this.props.children}</If>
            </div>
          </Styled.ContentWrapper>
        </Styled.StateNotification>
      </Container>
    )
  }
}
