import { FontWeight } from './fontWeight.types'

const fontWeight: FontWeight = {
  1: 200,
  2: 400,
  3: 700,
  4: 900,
}

export default fontWeight
