import { IconRuleSet } from '@vfuk/core-icon-generator/dist/IconGenerator.types'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

const ruleSet = (theme: Theme): IconRuleSet => {
  return {
    colorAppliesTo: 'fill',
    defaultSize: 3,
    appearance: {
      primary: {
        color: theme.color.primary3,
        inverse: {
          color: theme.color.primary2,
        },
      },
      secondary: {
        color: theme.color.primary1,
        inverse: {
          color: theme.color.primary1,
        },
      },
      alt1: {
        color: theme.color.secondary1,
        inverse: {
          color: theme.color.secondary1,
        },
      },
    },
    state: {
      success: {
        color: theme.color.success,
      },
      error: {
        color: theme.color.error,
      },
      info: {
        color: theme.color.info,
      },
      warn: {
        color: theme.color.warn,
      },
    },
    size: {
      1: {
        sm: 16,
        md: 16,
        lg: 16,
        xl: 16,
      },
      2: {
        sm: 16,
        md: 24,
        lg: 24,
        xl: 24,
      },
      3: {
        sm: 24,
        md: 32,
        lg: 32,
        xl: 32,
      },
      4: {
        sm: 24,
        md: 32,
        lg: 40,
        xl: 40,
      },
      5: {
        sm: 32,
        md: 40,
        lg: 48,
        xl: 48,
      },
    },
  }
}

export default ruleSet
