import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { HeadingTheme } from './Heading.theme.types'

const headingLocal = (theme: Theme): HeadingTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome8,
        inverse: {
          color: theme.color.monochrome1,
        },
      },
      secondary: {
        color: theme.color.monochrome7,
        inverse: {
          color: theme.color.monochrome2,
        },
      },
    },
  }
}

export default headingLocal
