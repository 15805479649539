import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/ListItem.style'

import { ListItemProps } from './ListItem.types'

const ListItem: FC<ListItemProps> = ({
  className,
  role,
  children,
  onClick,
  url,
  to,
  id,
  onMouseEnter,
  onMouseLeave,
  srText,
  htmlAttributes,
  interactionRef,
  listType = 'unordered',
  listStyle = 'none',
}: ListItemProps): ReactElement => {
  return (
    <Styled.ListItem
      id={id}
      className={className}
      role={role}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      listType={listType}
      listStyle={listStyle}
    >
      <Choose>
        <When condition={onClick || url || to}>
          <Styled.InteractionListItem onInteraction={onClick} href={url} to={to} label={srText} domRef={interactionRef} {...htmlAttributes}>
            {children}
          </Styled.InteractionListItem>
        </When>
        <Otherwise>{children}</Otherwise>
      </Choose>
    </Styled.ListItem>
  )
}

export default ListItem
