import { BaseFont } from './baseFont.types'
import fontWeight from './fontWeight'
import typography from './typography'
import colors from './colors'

const baseFont: BaseFont = {
  fontSize: typography.paragraph[3].fontMobile,
  fontWeight: fontWeight[2],
  color: colors.monochrome8,
  backgroundColor: colors.monochrome2,
  fontFamily: 'Vodafone, Open Sans, sans-serif',
  lineHeight: typography.paragraph[3].lineHeightMobile,
}

export default baseFont
