import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledParagraphProps } from './Paragraph.style.types'

export const Paragraph = styled.p((props: StyledParagraphProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.typography.paragraph(props.size!, !props.noMargin)}
  
  ${props.weight && `
    font-weight: ${props.theme.fontWeight[props.weight]};
  `}

  ${props.justify && `
    text-align: ${props.justify};
  `}

  &:last-child {
    margin-bottom: 0;
  }
`)
