import { Colors } from '@vfuk/core-styles/dist/constants/colors.types'

const colors: Colors = {
  // primary
  primary1: '#E60000',
  primary2: '#FFFFFF',
  primary3: '#4A4D4E',

  // secondary
  secondary1: '#007C92',
  secondary2: '#5E2750',
  secondary3: '#EB9700',
  secondary4: '#00B0CA',
  secondary5: '#A8B400',
  secondary6: '#9C2AA0',
  secondary7: '#FECB00',
  secondary8: '#FF2F2F',

  // accent
  accent1: '#990000',
  accent2: '#428600',
  accent3: '#BD0000',
  accent4: '#005EA5',
  accent5: '#FF0000',
  accent6: '#50A101',

  // monochrome
  monochrome1: '#FFFFFF',
  monochrome2: '#F4F4F4',
  monochrome3: '#EBEBEB',
  monochrome4: '#CCCCCC',
  monochrome5: '#AFAFAF',
  monochrome6: '#999999',
  monochrome7: '#666666',
  monochrome8: '#333333',
  monochrome9: '#222222',
  monochrome10: '#000000',

  // transparency
  opacity1: 'rgba(255, 255, 255, 0.25)',
  opacity2: 'rgba(255, 255, 255, 0.5)',
  opacity3: 'rgba(255, 255, 255, 0.75)',
  opacity4: 'rgba(1, 1, 1, 0.25)',
  opacity5: 'rgba(1, 1, 1, 0.5)',
  opacity6: 'rgba(1, 1, 1, 0.75)',

  // gradients
  gradient1: 'to right, #990000, #ff0000',
  gradient2: 'to right, #333333, #4A4D4E',

  success: '#428600',
  error: '#BD0000',
  info: '#055EA5',
  warn: '#E99600',
  focus: '#00B0CA',
  disabled: '#999999',
  incomplete: '#4A4D4E',
}

export default colors
