import { css, FlattenSimpleInterpolation } from 'styled-components'

import respondTo from './respondTo'

import { MixinSpacing, properties, spacingPresets } from './spacing.types'

import { Theme } from '../themes/themes.types'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: properties, value: spacingPresets): FlattenSimpleInterpolation =>
    theme.spacing.responsive[value] &&
    css`
      ${property}: ${theme.spacing.responsive[value].sm}px;

      ${respondTo.md(css`
        ${property}: ${theme.spacing.responsive[value].md}px;
      `)}

      ${respondTo.lg(css`
        ${property}: ${theme.spacing.responsive[value].lg}px;
      `)}
    ${respondTo.xl(css`
        ${property}: ${theme.spacing.responsive[value].xl}px;
      `)}
    `,
) as MixinSpacing
