import React, { PureComponent, ReactNode } from 'react'

import { withTheme } from 'styled-components'

import { IconProps } from './Icon.types'

import * as Styled from './styles/Icon.style'

export class Icon extends PureComponent<IconProps> {
  public static defaultProps: Partial<IconProps> = {
    isResponsive: true,
    appearance: 'primary',
    inverse: false,
    group: 'system',
  }

  private checkRuleFields = (): boolean => {
    // If the icon group does not exist then return true
    if (!this.props.theme!.doesIconGroupExist(this.props.group!)) return true

    const iconProperties = this.props.theme!.getIconProperties(this.props.group!)

    const errors: Set<boolean> = new Set()

    // Checks if it has an appearance, and if it does check whether the appearance is valid
    if (iconProperties.appearance) {
      errors.add(!iconProperties.appearance[this.props.appearance!])
    }

    // Checks if it has a state, and if it does check whether the state is valid
    if (iconProperties.state && this.props.state) {
      errors.add(!iconProperties.state[this.props.state])
    }

    // Checks if there are any errors, if so then return true
    return errors.has(true)
  }

  public render(): ReactNode {
    if (this.checkRuleFields()) return <Styled.ErrorPinkBox />

    return (
      <Styled.Icon
        state={this.props.state!}
        aria-hidden='true'
        appearance={this.props.appearance!}
        inverse={this.props.inverse!}
        size={this.props.size}
        isResponsive={this.props.isResponsive!}
        group={this.props.group!}
      >
        {this.props.theme!.getIcon(this.props.name, this.props.group!, <Styled.ErrorPinkBox />)}
      </Styled.Icon>
    )
  }
}

export default withTheme(Icon)
