import { css, FlattenSimpleInterpolation } from 'styled-components'

import respondTo from './respondTo'

import { MixinAdvancedSpacing, AdvancedSpacingValues, properties } from './advancedSpacing.types'

import { Theme } from '../themes/themes.types'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: properties, values: AdvancedSpacingValues): FlattenSimpleInterpolation => css`
    ${property}: ${values.map((value) => {
      return `${theme.spacing.responsive[value!].sm}px `
    })};

    ${respondTo.md(css`
      ${property}: ${values.map((value) => {
        return `${theme.spacing.responsive[value!].md}px `
      })};
    `)}

    ${respondTo.lg(css`
      ${property}: ${values.map((value) => {
        return `${theme.spacing.responsive[value!].lg}px `
      })};
    `)}

    ${respondTo.xl(css`
      ${property}: ${values.map((value) => {
        return `${theme.spacing.responsive[value!].xl}px `
      })};
    `)}
  `,
) as MixinAdvancedSpacing
