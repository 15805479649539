import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import colors from '@vfuk/core-styles/dist/constants/colors'

import { StyledInteractionProps } from './Interaction.style.types'

export const Button = styled.button((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.focus};
  }

  ${props.fullSize && css`
    width: 100%;
    height: 100%;
  `}
`)

export const Interaction = styled.div((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  text-decoration: none;
  color: inherit;
  display: inline-block;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.focus};
  }

  ${props.fullSize && css`
    width: 100%;
    height: 100%;
  `}
`)

export const BlankInteraction = styled.div((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  text-decoration: none;
  color: inherit;

  ${props.fullSize && css`
    width: 100%;
    height: 100%;
  `}
`)
