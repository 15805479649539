import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { DividerTheme } from './Divider.theme.types'

const dividerLocal = (theme: Theme): DividerTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome3,
        inverse: {
          color: theme.color.monochrome7,
        },
      },
      secondary: {
        color: theme.color.monochrome4,
        inverse: {
          color: theme.color.monochrome8,
        },
      },
      alt1: {
        color: theme.color.primary1,
        inverse: {
          color: theme.color.opacity2,
        },
      },
      alt2: {
        color: theme.color.primary1,
        inverse: {
          color: theme.color.opacity1,
        },
      },
      alt3: {
        color: theme.color.primary3,
        inverse: {
          color: theme.color.primary2,
        },
      },
      alt4: {
        // TODO: Add colour 4, once created in colour story
        // color: theme.color.primary4,
        color: '#4A4D4E',
        inverse: {
          color: theme.color.primary2,
        },
      },
    },
    level: {
      1: {
        borderStyle: theme.border.style[1],
      },
      2: {
        borderStyle: theme.border.style[2],
      },
    },
  }
}

export default dividerLocal
