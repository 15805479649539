import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LinkTheme } from './Link.theme.types'

const linkLocal = (theme: Theme): LinkTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome8,
        inverse: {
          color: theme.color.primary2,
        },
      },
      secondary: {
        color: theme.color.monochrome7,
        inverse: {
          color: theme.color.primary2,
        },
      },
    },
  }
}

export default linkLocal
