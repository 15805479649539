import styled, { css, createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components'

import { StyledOverlayProps } from './Overlay.style.types'

type OverflowProps = Pick<StyledOverlayProps, 'show' | 'overflowHidden'>
export const HideOverflow = createGlobalStyle((props: OverflowProps): FlattenSimpleInterpolation => css`
  ${props.show && props.overflowHidden && css`
    html,
    body {
      overflow: hidden;
    }
  `}
`)

type OverlayProps = Pick<StyledOverlayProps, 'theme' | 'position' | 'zIndex' | 'blur'>
export const Overlay = styled.div((props: OverlayProps): FlattenSimpleInterpolation => css`
  background: ${props.theme.color.opacity5};
  position: ${props.position};
  z-index: ${props.zIndex};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props.blur && `
    backdrop-filter: blur(5px);
  `}
`)
