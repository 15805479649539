import { css, FlattenSimpleInterpolation } from 'styled-components'

import { MixinElevation } from './elevation.types'

import { Theme } from '../themes/themes.types'

import createMixin from '../utils/createMixin'

export default createMixin(
  (theme: Theme, elevation: boolean = false): FlattenSimpleInterpolation => css`
    box-shadow: ${elevation ? theme.elevation.boxShadow : 'none'};
  `,
) as MixinElevation
